<template>
  <div class="about">
    <div class="tip">
      <div>关于我们</div>
      <div class="miniTitle">Strawberry roll</div>
    </div>
    <div class="contentBox">
      <div class="leftBox">
        <img class="img" src="../../assets/a1.jpg" alt="">
        <img class="img" src="../../assets/a2.jpg" alt="">
        <div class="footerImg">
          <img class="img" src="../../assets/a3.png" alt="">
        </div>

      </div>
      <div class="rightBox">
        <div>草莓卷出品首发于2016年，服务行业客户1w+</div>
        <div>公司坐落于杭州滨江高新开发区。</div>
        <div>获得国家<span style="color: #00b15d">高新科技企业称号</span>,注重技术研发创新!</div>
        <div>ceo王睿获得 <span style="color: #00b15d">《中国商界》</span>独家采访</div>
        <div>公司专业从事艺术创作类行业saas平台服务。</div>
        <div>致力于打造全国领先的艺术创作类企业一站式的互联网信息化解决方案!</div>
        <div style="margin-top: 70px">王睿荣获第十届创新创业领袖峰会“年度杰出CEO”奖</div>

        <div class="box_footer">

        </div>
      </div>
    </div>
    <div class="box_footer">
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.contentBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

}
.about {
  padding-top: 130px; /* 根据 sticky 元素的高度进行调整 */
  margin-top: -55px; /* 根据 sticky 元素的高度进行调整 */
}
.rightBox {
  font-size: 16px;
  div {
    margin: 4px;
  }
}
.leftBox {
  margin-right: 40px;
  .img{
    width: 150px;
    margin-right: 10px;
  }
  .footerImg {
    margin-top: 20px;
    .img {
      margin: 0;
      float: right;
      width: 150px;

    }
  }
}
.tip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  .miniTitle {
    font-weight: 400;
    font-size: 18px;
  }
}
</style>
<template>
  <div class="product">
    <div class="tip">
      <div>我们为商家提供的服务</div>
      <div class="miniTitle">Service</div>
    </div>
    <div class="cardBox">
      <div class="cardItem" @click="goUrl('https://client.inwuoo.com')">
        <div class="icon">
          <img src="../../assets/p1.png" alt="">
        </div>
        <div class="name">小程序&接单</div>
        <div class="title">在手机和桌面尽情展示</div>
        <div class="title">您的优质内容</div>
        <div class="btn">进入系统</div>
      </div>
      <div class="cardItem" @click="goUrl('http://formal.crm.inwuoo.com')">
        <div class="icon">
          <img src="../../assets/p2.png" alt="">
        </div>
        <div class="name">客资系统</div>
        <div class="title">随时随地管理客资</div>
        <div class="title">不放走每一个成交机会</div>
        <div class="btn">进入系统</div>
      </div>
      <div class="cardItem" @click="goUrl('http://dressv2.inwuoo.com')">
        <div class="icon">
          <img src="../../assets/p3.png" alt="">
        </div>
        <div class="name">礼服管理</div>
        <div class="title">数据化管理礼服档期</div>
        <div class="title">手机一体化操作</div>
        <div class="btn">进入系统</div>
      </div>
      <div class="cardItem" @click="goUrl('https://merchant.inwuoo.com/')">
        <div class="icon">
          <img src="../../assets/p4.png" alt="">
        </div>
        <div class="name">草莓智能客服</div>
        <div class="title">利用AI智能全面升级</div>
        <div class="title">您的客服体系</div>
        <div class="btn">进入系统</div>
      </div>
    </div>
  </div>
</template>

<script setup>
const goUrl = (url) => {
  window.open(url)
}
</script>

<style lang="scss" scoped>
.product {
  padding-top: 95px; /* 根据 sticky 元素的高度进行调整 */
  margin-top: -55px; /* 根据 sticky 元素的高度进行调整 */
}
.tip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  .miniTitle {
    font-weight: 400;
    font-size: 18px;
  }
}
.cardBox {
  display: flex;
  margin-top: 40px;
  .cardItem {
    border: 1px solid #d0d0d0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 50px 20px;
    flex: 1;
    margin: 10px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    .icon {
      border: 1px solid #00b15d;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
        color: #00b15d;
      }
    }
    .name {
      margin: 10px 0;
      font-size: 18px;
      font-weight: bold;
    }
    .title {
      color: #959595;
      font-size: 12px;
    }
    .btn {
      border: 1px solid #d0d0d0;
      color: #959595;
      padding: 4px 20px;
      border-radius: 20px;
      font-size: 12px;
      margin-top: 20px;
    }
  }

  .cardItem:hover {
    border: 1px solid #00b15d;
    color: #00b15d;
    box-shadow: rgba(41, 103, 190, .15) 0 10px 30px 0;
    .title {
      color: #00b15d;

    }
    .btn {
      border: 1px solid #00b15d;
      color: #00b15d;
    }
  }
}
</style>
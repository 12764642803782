import { createRouter, createWebHistory } from 'vue-router'
import WebsiteIndex from '../views/website-index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: WebsiteIndex
  },
  { path: '/:pathMatch(.*)', redirect: { name: 'home' } }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <div class="homeBox">
    <div class="leftBox">
      <div class="boldClass" style="font-size: 32px;">Strawberry Roll</div>
      <div >草莓卷数据营销平台</div>
      <div class="boldClass" style="margin-top: 20px; color: #00b15d; font-size: 40px;">优质用户体验</div>
      <div class="boldClass" style="fontSize: 40px;">&数字智能方案</div>
      <div style="margin-top: 20px; fontSize: 16px;">新时代 》您的优质销售拍档</div>
      <div style="color: #00b15d; fontSize: 16px;margin-top:2px">国家高新科技企业</div>
    </div>
    <div class="rightBox">
      <img src="../../assets/b1.jpg" alt="">
      <div>Power by AI</div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.leftBox {
  div {
    font-size: 30px;
  }
  .boldClass{
    font-weight: 700;
  }
}
.homeBox {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 180px; /* 根据 sticky 元素的高度进行调整 */
  margin-top: -55px; /* 根据 sticky 元素的高度进行调整 */
}
.rightBox {
  img {
    width: 400px;
  }
  div {
    font-size: 12px;
  }
}
</style>
<template>
  <div class="contactUs" v-if="showFlag">
    <div class="maskBox" @click="showFlag = false"></div>
    <div class="codeBox">
      <div class="close" @click="showFlag = false">x</div>
      <img src="../../assets/code.jpg" alt="">
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue"
const showFlag = ref(false)
const open = () => {
  showFlag.value = true
}
defineExpose({open})
</script>

<style lang="scss" scoped>
.maskBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.8);
}
.codeBox {
  position: fixed;
  width: 500px;
  height: 500px;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    width: 400px;
    height: 400px;
  }
  .close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    border: 1px solid #545454;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
  }
}
</style>
<template>
  <div class="partner">
    <div class="tip">
      <div>合作伙伴</div>
      <div class="miniTitle">Partner</div>
    </div>
    <div class="contentBox">
        <img src="../../assets/f1.jpg" alt="">
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.partner {
  margin-top: 70px;
}
.contentBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.tip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  .miniTitle {
    font-weight: 400;
    font-size: 18px;
  }
}
</style>
<template>
  <router-view/>
</template>

<style lang="scss">
a {
  text-decoration: none;
  color: #000;
}
</style>

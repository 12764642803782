<template>
  <div class="public-head">
    <div class="head-logo">
      <img class="logoImg" src="../../assets/img.png" alt="">
      <div class="logoTitle">
        <div>草莓卷.映屋</div>
        <div class="miniTitle">Strawberry Roll</div>
      </div>
    </div>
    <div class="tabBox">
      <div @click="clickTab(item)" class="tabItem" :class="{active: activeTab === item.id}" v-for="item in tabList" :key="item.id">
        <a :href="item.href">{{item.name}}</a>
      </div>
    </div>
    <div class="contact" @click="contact">
      <img style="width: 30px; height: 30px;" src="../../assets/icon-weixin.png" alt="">
      <span>联系我们</span>
    </div>
  </div>
  <ContactUs ref="ContactUsRef"></ContactUs>

</template>

<script setup>
import {reactive, onMounted, ref} from 'vue'
import ContactUs from "./contact-us"
const tabList = ref([
  {id: 1, name:"首页", href: "#home"},
  {id: 2, name:"产品", href: "#product"},
  {id: 3, name:"关于我们", href: "#about"},
  {id: 4, name:"合作伙伴", href: "#partner"},
])
const activeTab = ref(1)
const clickTab = (item) => {
  activeTab.value = item.id
}
const changeTab = (val) => {
  activeTab.value = val
}
const ContactUsRef = ref()
const contact = () => {
  ContactUsRef.value.open()
}
defineExpose({changeTab})
</script>

<style scoped lang="scss">
.tabBox {
  display: flex;
  .tabItem {
    cursor: pointer;
    margin: 0 40px;
    font-weight: bold;
    position: relative;
    a {
      font-weight: 400;
    }
    &:hover{
      a {
        font-weight: bold;
        color: #00b15d;
      }
    }
  }
  .active::before{
    content: "";
    display: block;
    position: absolute;
    bottom: -5px;
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background: #00b15d;
  }
  .active a {
    color: #00b15d;
  }
}
.contact {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.public-head {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  backdrop-filter: blur(10px);
  background: rgba(255,255,255,0.5);
  box-sizing: border-box;
  .head-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    .logoImg {
      height: 40px;
      object-fit: contain;
    }
    .logoTitle{
      font-weight: bold;
      font-size: 22px;
      margin-left: 10px;
      .miniTitle {
        font-size: 12px;
      }
    }
  }
}
</style>
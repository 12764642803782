<template>
  <div class="webSite">
    <div class="publicHead">
      <publicHead ref="publicHeadRef"></publicHead>
    </div>
    <div class="content">
      <div id="home" ref="homeRef">
        <HomeBox></HomeBox>
      </div>
      <div id="product" ref="productRef">
        <product></product>
      </div>
      <div id="about" ref="aboutRef">
        <about></about>
      </div>
      <div id="partner" ref="partnerRef">
        <partner></partner>
      </div>
    </div>
    <div class="footerBox">
      <div @click="goBeiAn">版权所有 ©草莓卷 All rights reserved Version v1.0</div>
      <div @click="goBeiAn">浙ICP备2020032890号-1</div>
      <div class="head-logo">
        <div class="logoTitle">
          <img class="logoImg" src="../assets/img.png" alt="">
          <div style="margin: 10px 0 0">草莓卷.映屋</div>
          <div class="miniTitle">Strawberry Roll</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import publicHead from "@/components/public-head/index.vue"
import HomeBox from "@/components/home-box/index.vue"
import partner from "@/components/partner/index.vue"
import product from "@/components/product/index.vue"
import about from "@/components/about/index.vue"
import {ref, onMounted, onBeforeUnmount} from "vue"

const debounce = (fn, delay) => {
  let timer = null
  return function() {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}
const publicHeadRef = ref()
const scrollDistance = ref('')
const handleScroll = debounce( () => {
  scrollDistance.value = window.pageYOffset || document.documentElement.scrollTop;
  if(scrollDistance.value < 400) {
    publicHeadRef.value.changeTab(1)
  }else if(scrollDistance.value < 750) {
    publicHeadRef.value.changeTab(2)
  }else if(scrollDistance.value < 1040){
    publicHeadRef.value.changeTab(3)
  }else{
    publicHeadRef.value.changeTab(4)
  }
}, 200)

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

const goBeiAn = () => {
  window.open("https://beian.miit.gov.cn/#/Integrated/index")
}

</script>
<style lang="scss" scoped>
.webSite {
  width: 100%;
  position: relative;
  .content {
    width: 1200px;
    margin: auto;
  }
}
.footerBox {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 100px 0 200px;
  margin-top: 100px;
  border-top: 1px solid #d0d0d0;
}
.head-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  .logoTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
    margin-left: 10px;
    .miniTitle {
      font-size: 12px;
    }
    .logoImg {
      height: 40px;
      object-fit: contain;
    }
  }
}

</style>